import React, { useRef, useEffect } from 'react';
import LoadFonts from './LoadFonts';
import './chatbot.css';
import { useState } from 'react';
import axiosClient from './axiosClient';


function Chatbot() {
    const BotName = "Vivya";
    const [showChatbot, setShowChatbot] = useState(false);
    const [showButtons, setShowButtons] = useState(false);
    const [chatStatus, setChatStatus] = useState(false);
    const [threadId, setThreadId] = useState('');
    const [messages, setMessages] = useState([]);
    const [inputText, setInputText] = useState("");
    const [thinking, setThinking] = useState(false);
    
    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);



    const startChat = async () => {
        setThinking(true);
        try {
            const response = await axiosClient.get('/start');
            console.log(response.data.thread_id);
            setThreadId(response.data.thread_id);
            if (response.data.thread_id !== "") {
                setMessages([...messages, { message: "Hi, I am "+BotName+". How can I help you?", messageType: "bot", error: false }]);
            }
            setThinking(false);
        } catch (error) {
            setMessages([...messages, { message: "Oops! Something went wrong. Please try again by refreshing the page", messageType: "bot", error: true }]);
            console.error('There was an error!', error);
            
        }
        setThinking(false);
    }

    const sendMessage = async () => {

        if (threadId == "") {
            window.location.reload();
         }

        let messageToSend = inputText.trim();
        setInputText("");

        // Add the user message to the chat
        setMessages(prevMessages => [...prevMessages, { message: messageToSend, messageType: "user", error: false }]);
        setThinking(true);

        try {
            const response = await axiosClient.post('/chat', {
                message: messageToSend,
                thread_id: threadId
            });

            console.log(response);

            if (response.data.response !== "") {
                // Add the bot's response to the chat
                setMessages(prevMessages => [...prevMessages, { message: response.data.response.replace(/【[^】]*】/g, "").trim().replace(/\s+\./g, '.'), messageType: "bot", error: false }]);
            }
        } catch (error) {
            // Handle any errors by adding an error message to the chat
            setMessages(prevMessages => [...prevMessages, { message: "Oops! Something went wrong. Please try again by refreshing the page", messageType: "bot", error: true }]);
            console.error('There was an error!', error);
        }
        setThinking(false);
    };

    const endChat=()=> {
        setThreadId("");
        setMessages([]);
        setShowButtons(false);
        setShowChatbot(false)
        setChatStatus(false);
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) { // If Enter is pressed without Shift
            event.preventDefault(); // Prevent the default Enter action
            sendMessage();
        }
    };


    return (
        <>
            <LoadFonts />
            <div className={`chat-space-window ${showChatbot ? 'show-chatbot' : ''}`}>
                <button className="chatbot-toggler" onClick={() => {
                    setShowChatbot(!showChatbot);
                    if (!chatStatus) {
                        setChatStatus(!chatStatus);
                        startChat();

                    }
                }}>
                    <span className="material-symbols-rounded">mode_comment</span>

                </button>
                <div className="chatbot">
                    <header>
                        <h2>{ BotName}</h2>
                        <span className="close-btn material-symbols-outlined" id="minimize" onClick={() => setShowChatbot(!showChatbot)}>
                            minimize
                        </span>
                        <span className="close-btn material-symbols-outlined" id="close" onClick={() => {
                            if (!showButtons) {
                                setShowButtons(!showButtons);
                            }
                        }
                        }>
                            close
                        </span>
                    </header>
                    <ul className="chatbox">
                        {messages.map((message, index) => (
                            <li key={index} className={`chat ${message.messageType == "bot" ? "incoming" : "outgoing"}`}>
                                {message.messageType === "bot" && <span className="material-symbols-outlined">smart_toy</span>}
                                <p className={`${message.error ? 'error' : ''} `}>{message.message}</p>
                            </li>
                        ))}

                        {thinking && <li class="chat incoming">
                            <span class="material-symbols-outlined">smart_toy</span>
                            <p>Thinking...</p>
                        </li>}

                        <div ref={messagesEndRef} />

                    </ul>
                    <div className="chat-input">
                        <textarea
                            placeholder="Enter a message..."
                            spellCheck="false"
                            required=""
                            value={inputText}
                            onChange={(e) => setInputText(e.target.value)}
                            defaultValue={""}
                            onKeyDown={handleKeyDown}
                        />
                        <span id="send-btn" className="material-symbols-rounded" onClick={sendMessage}>
                            send
                        </span>
                    </div>
                    <div className={`button-container ${showButtons ? 'show-btn-window' : ''}`}>
                        <button className="end-chat-btn" onClick={endChat}>End Chat</button>
                        <br />
                        <button className="cancel-btn" onClick={() => setShowButtons(!showButtons)}>Cancel</button>
                    </div>

                </div>
            </div>
        </>

    );
}

export default Chatbot;
