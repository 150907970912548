import { useEffect } from 'react';

const LoadFonts = () => {
    useEffect(() => {
        const link1 = document.createElement('link');
        link1.href = 'https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0';
        link1.rel = 'stylesheet';

        const link2 = document.createElement('link');
        link2.href = 'https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@48,400,1,0';
        link2.rel = 'stylesheet';

        document.head.appendChild(link1);
        document.head.appendChild(link2);

        return () => {
            document.head.removeChild(link1);
            document.head.removeChild(link2);
        };
    }, []);

    return null; // This component does not render anything
};

export default LoadFonts;
