import Chatbot from "./Chatbot/chatbot";

function App() {
  return (
    <>
     <Chatbot/>
    </>
  );
}

export default App;
