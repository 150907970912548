// axiosClient.js
import axios from 'axios';

const axiosClient = axios.create({
    baseURL: 'https://chat.ikon.global', // Replace with your base URL
    /* Other custom settings */
    headers: {
        'Content-Type': 'application/json',
        // Add other headers here
    },
});



export default axiosClient;
